@import "scss/index";
@keyframes openNotification {
  from {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.notification {
  width: 360px;
  z-index: 2;
  animation: openNotification 0.3s $ease-in-out-quadratic;

  &.closed {
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s $ease-out-quadratic;
  }
}
