@import "scss/index";
@keyframes openModal {
  0% {
    transform: translate3d(0, 50%, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.container {
  width: 100%;
  min-height: 100%;
  height: auto;
  //animation: openModal 0.35s ease;
}

.plainModal {
  width: 100%;
  height: auto;
  min-height: 100%;

  &,
  &:focus {
    outline: none;
  }
}

.closeButton {
  position: absolute !important;
  z-index: 10;

  &.android {
    top: 48px;
    right: 48px;
  }
}


@include media-mobile {

  .container {
    position: absolute;
    left: 0;
    width: 100%;
    //height: 100%;
  }

  .plainModal {
    width: 100%;
    min-height: 100%;
    padding: 40px 16px 16px;
  }

  .closeButton {
    top: 24px;
    right: 24px;
  }
}

@include media-tablet {

  .container {
    justify-content: center;
  }

  .closeButton {
    top: 24px;
    right: 24px;
  }
}

@include media-desktop {

  .overlay {
    display: grid;
  }

  .container {
    //display: flex;
    //align-items: center;
    justify-content: center;
    margin: auto;
    //padding: 16px;
  }

  .closeButton {
    top: 32px;
    right: 32px;
  }
}
