@import "scss/index";
.tooltip {
  position: absolute;
  z-index: $layer-modal + 1;
  display: none;
  background-color: $color-white;
  pointer-events: none;

  &.visible {
    display: block;
  }

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    content: '';
  }

  &.top-left {

    &::after {
      right: 12px;
      bottom: -6px;
      border-top: 8px solid #fff;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }

  &.bottom-right {

    &::after {
      top: -6px;
      left: 12px;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
      border-left: 8px solid transparent;
    }
  }
}


@include media-desktop {

  .tooltip {
    max-width: 400px;
  }
}
